import { FC } from 'react';
import { BasicSelect, BasicSelectProps } from './BasicSelect';
import { Tooltip, TooltipProps } from '@components/Tooltip';
import { SelectWithTooltipContainer } from './styles';

export interface SelectProps extends BasicSelectProps {
  tooltipProps?: Omit<TooltipProps, 'id'>;
}

export const Select: FC<SelectProps> = ({ tooltipProps, width, name, ...selectProps }) =>
  tooltipProps ? (
    <SelectWithTooltipContainer $width={width}>
      <BasicSelect {...selectProps} name={name} width="100%" />
      <Tooltip {...tooltipProps} id={`${name}-tooltip`} />
    </SelectWithTooltipContainer>
  ) : (
    <BasicSelect {...selectProps} name={name} width={width} />
  );
