import { User } from '@models/user';
import { hasPermission } from '@utils/user';

export const isOrdersCreationToggleOn = (userInfo: User) => hasPermission(userInfo, 'OrderCreation');

export const isCo2ReportToggleOn = (userInfo: User) => hasPermission(userInfo, 'CO2Report');

export const isOrdersManagementToggleOn = (userInfo: User) => hasPermission(userInfo, 'OrdersManagement');

export const isUsersManagementToggleOn = (user: User) => hasPermission(user, 'UserAccountManagement');

export const isUserCreationToggleOn = () => process.env.REACT_APP_USERS_CREATION_ALLOWED === 'true';

export const isDashboardReportToggleOn = (user: User) => hasPermission(user, 'ReportsDashboardAndCostDetails');

export const isCostReportToggleOn = (user: User) => hasPermission(user, 'ReportsDashboardAndCostDetails');

export const isWasteReportToggleOn = (user: User) => hasPermission(user, 'ReportsWasteDetails');

export const hasAtLeastOneReportPermission = (user: User) =>
  isWasteReportToggleOn(user) || isCostReportToggleOn(user) || isDashboardReportToggleOn(user);
