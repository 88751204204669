import { FC } from 'react';
import { InputWithTooltipContainer } from './styles';
import { BasicInputFieldProps, BasicInputField } from './BasicInputField';
import { Tooltip, TooltipProps } from '@components/Tooltip';

export interface InputFieldProps extends BasicInputFieldProps {
  tooltipProps?: Omit<TooltipProps, 'id'>;
}

export const InputField: FC<InputFieldProps> = ({ tooltipProps, width, name, ...inputProps }) =>
  tooltipProps ? (
    <InputWithTooltipContainer $width={width}>
      <BasicInputField name={name} {...inputProps} width="100%" />
      <Tooltip {...tooltipProps} id={`${name}-tooltip`} />
    </InputWithTooltipContainer>
  ) : (
    <BasicInputField name={name} {...inputProps} width={width} />
  );
