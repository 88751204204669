import { Tile } from '@components/Tile';
import { StyledDescriptionListItem, MediumDescriptionListItem } from '@components/styles';
import { DescriptionList } from '@fortum/elemental-ui';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderWasteDetails } from '@models/orders';
import { Namespace } from '@config/i18n';
import { hasValue } from '@utils/dataOperations';
import { NO_DATA_BADGE, getFullUnDescription, displayedValue, getHazardousInfoDescription } from '@utils/dataOperations';
import { ADRLabels, HPCodes, WasteConsistency, WasteRdCode } from '@components/wasteDetails';
import { WasteEwcCode } from '@components/wasteDetails/WasteEwcCode';
import { translate } from '@utils/internationalization';
import { getWasteQuantityEntryDetails } from './utils';

interface WasteDetailsTileProps {
  wasteDetails: OrderWasteDetails;
}

export const WasteDetailsTile: FC<WasteDetailsTileProps> = ({ wasteDetails }) => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderDetails', 'orderCreation']);

  const { quantityEntryTestId, quantityEntryLabel, quantityValue } = getWasteQuantityEntryDetails(
    wasteDetails.estimation,
    wasteDetails.quantity,
  );

  const adrValue =
    wasteDetails.adrWaste21355 === null ? NO_DATA_BADGE : translate(`${wasteDetails.adrWaste21355}`, 'domain', 'adrWaste');

  const animalicByProductValue =
    wasteDetails.animalicByProduct === null
      ? NO_DATA_BADGE
      : translate(`${wasteDetails.animalicByProduct}`, 'domain', 'animalicByProduct');

  return (
    <Tile header={t('wasteDetails:wasteDetails.details')} data-testid={getTileTestId('waste-details')}>
      <DescriptionList size="condensed">
        <MediumDescriptionListItem
          data-testid={getListItemTestId('waste-description')}
          label={t('wasteDetails:wasteDescription')}
        >
          {displayedValue(wasteDetails.wasteDescription)}
        </MediumDescriptionListItem>

        {hasValue(wasteDetails.additionalWasteDescription) && (
          <StyledDescriptionListItem
            data-testid={getListItemTestId('additional-waste-description')}
            label={t('orderCreation:form.specifiedWasteDescription.label')}
          >
            {wasteDetails.additionalWasteDescription}
          </StyledDescriptionListItem>
        )}

        <StyledDescriptionListItem data-testid={quantityEntryTestId} label={quantityEntryLabel}>
          {quantityValue}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          data-testid={getListItemTestId('waste-consistency')}
          label={t('wasteDetails:wasteDetails.wasteConsistency')}
        >
          <WasteConsistency consistency={wasteDetails.wasteConsistency} />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('ewc-code')} label={t('wasteDetails:wasteDetails.ewcCode')}>
          <WasteEwcCode ewcCode={wasteDetails.ewcCode} />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('hazard')} label={t('wasteDetails:wasteDetails.hazard')}>
          {getHazardousInfoDescription(wasteDetails.hazardous)}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('rd-code')} label={t('wasteDetails:wasteDetails.rdCode')}>
          <WasteRdCode code={wasteDetails.rdCode} />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          data-testid={getListItemTestId('hazardous-material-classes')}
          label={t('wasteDetails:wasteDetails.adrLabels')}
        >
          <ADRLabels
            hazardousMaterialClasses={wasteDetails.adrLabels}
            environmentalHazardous={wasteDetails.environmentalHazardous}
          />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('un-number')} label={t('wasteDetails:wasteDetails.unNumber')}>
          {getFullUnDescription(wasteDetails.unNumber, wasteDetails.packagingGroup, wasteDetails.hazMatTypeDescription)}
        </StyledDescriptionListItem>

        {hasValue(wasteDetails.adrNosComment) && (
          <StyledDescriptionListItem
            data-testid={getListItemTestId('adr-nos-comment')}
            label={t('wasteDetails:wasteDetails.adrNosComment')}
          >
            {wasteDetails.adrNosComment}
          </StyledDescriptionListItem>
        )}

        <StyledDescriptionListItem data-testid={getListItemTestId('adr-waste')} label={t('wasteDetails:wasteDetails.adrWaste')}>
          {adrValue}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          data-testid={getListItemTestId('environmental-hazardous')}
          label={t('wasteDetails:wasteDetails.environmentalHazardous')}
        >
          {translate(`${wasteDetails.environmentalHazardous}`, 'domain', 'environmentallyHazardous')}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem data-testid={getListItemTestId('hp-codes')} label={t('wasteDetails:wasteDetails.hpCodes')}>
          <HPCodes hpCodes={wasteDetails.hpCodes} />
        </StyledDescriptionListItem>

        {hasValue(wasteDetails.wasteOilType) && (
          <StyledDescriptionListItem
            data-testid={getListItemTestId('waste-oil-type')}
            label={t('wasteDetails:wasteDetails.wasteOilType')}
          >
            {translate(wasteDetails.wasteOilType, 'domain', 'wasteOilType')}
          </StyledDescriptionListItem>
        )}

        {hasValue(wasteDetails.popCompounds) && (
          <StyledDescriptionListItem
            data-testid={getListItemTestId('pop-compounds')}
            label={t('wasteDetails:wasteDetails.popCompounds')}
          >
            {translate(wasteDetails.popCompounds, 'domain', 'analysisPopCode')}
          </StyledDescriptionListItem>
        )}

        <StyledDescriptionListItem
          data-testid={getListItemTestId('animalic-by-product')}
          label={t('wasteDetails:wasteDetails.animalicByProduct')}
        >
          {animalicByProductValue}
        </StyledDescriptionListItem>
      </DescriptionList>
    </Tile>
  );
};
