import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { colors, ContentTextProps, fontSizes, fontWeights, IconButtonProps, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const HeaderContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
`;

export const PreviewContainer = styled(VerticalContainer)`
  gap: ${spacing.xs};
  min-height: 100%;
  box-sizing: border-box;
`;

export const headerTextStyles: ContentTextProps = {
  fontSize: fontSizes.xl,
  fontWeight: fontWeights.regular,
  alignSelf: 'end',
};

export const closeIconButtonStyles: Omit<IconButtonProps, 'icon'> = {
  size: 24,
  color: colors.inkGrey,
  alignSelf: 'start',
  paddingTop: spacing.xxxs,
};
