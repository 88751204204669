import { Namespace } from '@config/i18n';
import { Button, ChipItem, IconAddUser } from '@fortum/elemental-ui';
import { UserCompany } from '@models/usersManagement';
import { SEARCH_CHIP_VALUE } from '@utils/dataOperations';
import { createChipsItems } from '@utils/filtersAccordion';
import { FC, forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters } from './Filters';
import { ActionComponentsContainer, Container, StyledCommonHeader, StyledFiltersAccordion } from './styles';
import { isUserCreationToggleOn } from '@utils/featureToggles';

interface PageHeaderProps {
  companiesSelectionLimit: number;
  selectedCompanies: string[];
  companies: UserCompany[];
  searchQuery: string;
  onSearch: (value: string) => void;
  setSelectedCompanies: (companies: string[]) => void;
  onAddNewUserButtonClick: () => void;
}

const ActionComponents: FC<PageHeaderProps> = ({
  onSearch,
  setSelectedCompanies,
  onAddNewUserButtonClick,
  selectedCompanies,
  searchQuery,
  companies,
  companiesSelectionLimit,
}) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <ActionComponentsContainer>
      <Filters
        onSearch={onSearch}
        setSelectedCompanies={setSelectedCompanies}
        selectedCompanies={selectedCompanies}
        searchQuery={searchQuery}
        companies={companies}
        companiesSelectionLimit={companiesSelectionLimit}
      />

      {isUserCreationToggleOn() && (
        <Button size="s" status="secondary" rightIcon={<IconAddUser />} onClick={onAddNewUserButtonClick}>
          {t('addNewUser')}
        </Button>
      )}
    </ActionComponentsContainer>
  );
};

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(
  (
    {
      onSearch,
      setSelectedCompanies,
      selectedCompanies,
      searchQuery,
      companies,
      companiesSelectionLimit,
      onAddNewUserButtonClick,
    },
    ref,
  ) => {
    const { t } = useTranslation<Namespace>('usersManagement');

    const chipsItems = useMemo<ChipItem<string>[]>(
      () => createChipsItems(selectedCompanies, searchQuery),
      [selectedCompanies, searchQuery],
    );

    const resetAllFilters = useCallback(() => {
      setSelectedCompanies([]);
      onSearch('');
    }, [setSelectedCompanies, onSearch]);

    const removeChipItem = useCallback(
      ({ value }: ChipItem<string>) => {
        if (value === SEARCH_CHIP_VALUE) {
          onSearch('');
          return;
        }

        setSelectedCompanies(selectedCompanies.filter(selectedCompany => selectedCompany !== value));
      },
      [onSearch, setSelectedCompanies, selectedCompanies],
    );

    return (
      <Container ref={ref}>
        <StyledCommonHeader
          header={t('appMenuItem.label')}
          componentRight={
            <ActionComponents
              onSearch={onSearch}
              setSelectedCompanies={setSelectedCompanies}
              onAddNewUserButtonClick={onAddNewUserButtonClick}
              selectedCompanies={selectedCompanies}
              searchQuery={searchQuery}
              companies={companies}
              companiesSelectionLimit={companiesSelectionLimit}
            />
          }
        />

        {!!chipsItems.length && (
          <StyledFiltersAccordion chipsItems={chipsItems} clearAllFilters={resetAllFilters} removeFilter={removeChipItem} />
        )}
      </Container>
    );
  },
);

PageHeader.displayName = 'PageHeader';
