import { Testable, Stylable } from '@components/types';
import { InputFieldProps as ElementalInputFieldProps, CommonIconProps, renderComponentOrElement } from '@fortum/elemental-ui';
import { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import {
  ReadOnlyContainer,
  ReadOnlyInputField,
  ReadOnlyIconContainer,
  InputLabel,
  Container,
  StyledInputField,
  IconContainer,
  StyledErrorMessage,
} from './styles';

type NativeInputProps = ComponentPropsWithoutRef<'input'>;

export type BasicInputFieldProps = {
  label: string;
  name: string;
  required?: boolean;
  errorMessage?: string;
  showError?: boolean;
  size?: ElementalInputFieldProps['size'];
  icon?: ElementalInputFieldProps['leftIcon'];
} & Testable &
  Stylable &
  Omit<NativeInputProps, 'size'>;
const iconStyles: CommonIconProps = { size: 24 };

export const BasicInputField: FC<BasicInputFieldProps> = ({
  label,
  name,
  errorMessage,
  showError = false,
  icon,
  className,
  size = 'l',
  'data-testid': testId,
  readOnly = false,
  disabled,
  ...nativeProps
}) => {
  const labelText = useMemo<string>(() => (nativeProps.required ? `*${label}` : label), [nativeProps.required, label]);

  const { labelId, errorMessageId } = useMemo(() => ({ labelId: `${name}_label`, errorMessageId: `${name}_error` }), [name]);

  return readOnly ? (
    <ReadOnlyContainer className={className} $width={nativeProps.width}>
      <ReadOnlyInputField
        id={name}
        type="text"
        readOnly={readOnly}
        $size={size}
        {...nativeProps}
        $withIcon={!!icon}
        aria-required={nativeProps.required || undefined}
        aria-labelledby={labelId}
      />

      {icon && (
        <ReadOnlyIconContainer data-testid="left-icon-container" aria-label={label} $size={size}>
          {renderComponentOrElement(icon, iconStyles)}
        </ReadOnlyIconContainer>
      )}

      <InputLabel $size={size} id={labelId} htmlFor={name} $withIcon={!!icon}>
        {labelText}
      </InputLabel>
    </ReadOnlyContainer>
  ) : (
    <Container data-testid={testId} $error={showError} $disabled={!!disabled} className={className} $width={nativeProps.width}>
      <StyledInputField
        id={name}
        type="text"
        $size={size}
        {...nativeProps}
        $withIcon={!!icon}
        $error={showError}
        disabled={disabled}
        aria-disabled={!!disabled || undefined}
        aria-required={nativeProps.required || undefined}
        aria-labelledby={labelId}
        aria-invalid={!!showError || undefined}
        aria-describedby={showError ? errorMessageId : undefined}
      />

      {icon && (
        <IconContainer data-testid="left-icon-container" aria-label={label} $size={size} $error={showError}>
          {renderComponentOrElement(icon, iconStyles)}
        </IconContainer>
      )}

      <InputLabel $size={size} id={labelId} htmlFor={name} $withIcon={!!icon}>
        {labelText}
      </InputLabel>

      {showError && (
        <StyledErrorMessage id={errorMessageId} active>
          {errorMessage}
        </StyledErrorMessage>
      )}
    </Container>
  );
};
