import { CONTAINER_BORDER_RADIUS, VerticalContainer } from '@components/styles';
import { breakpoints, Modal, PlaceholderProps, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(VerticalContainer)`
  width: 100%;
  height: 100%;
`;
export const placeholderStyles: PlaceholderProps = {
  height: '24px',
  width: '90%',
  marginVertical: '8px',
};

export const StyledModal = styled(Modal)`
  --padding-vertical: 48px;
  max-width: 744px;
  width: 100%;
  box-sizing: border-box;
  max-height: ${`calc(100% - 2 * var(--padding-vertical))`};
  padding: ${spacing.xxs} 48px;
  justify-content: start;
  border-radius: ${CONTAINER_BORDER_RADIUS};

  :focus-visible {
    box-shadow: none;
    outline: none;
  }

  > div {
    width: 100%;
    align-items: start;
    text-align: unset;
    padding: 0;
    box-shadow: unset;
  }

  > div:nth-child(2) {
    height: 100%;
  }

  @media ${`(max-width: ${breakpoints.xxl}${'px'})`} {
    --padding-horizontal: 80px;
    max-width: ${`calc(100% - 2 * var(--padding-horizontal))`};
  }

  @media ${`(max-width: ${breakpoints.l}${'px'})`} {
    --padding-horizontal: 78px;
    max-width: ${`calc(100% - 2 * var(--padding-horizontal))`};
  }
`;
