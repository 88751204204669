import styled, { css } from 'styled-components';
import { PageHeader as CommonPageHeader } from '@components/PageHeader';
import { colors, spacing } from '@fortum/elemental-ui';
import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { FiltersAccordion } from '@components/FiltersAccordion';
import { Multiselect, MultiselectProps } from '@components/Multiselect';
import { InputFieldProps } from '@components/InputField';

export const FILTER_MIN_WIDTH = '7rem';
export const FILTER_MAX_WIDTH = '25rem';

export const StyledCommonHeader = styled(CommonPageHeader)`
  padding-left: 0px;
  padding-right: 0px;
`;

export const Container = styled(VerticalContainer)`
  padding: 0 ${spacing.xxl};
`;

export const FiltersContainer = styled(HorizontalContainer)`
  flex: 1;
  gap: ${spacing.xxs};
  justify-content: flex-end;
  margin-left: ${spacing.xs};

  & > div {
    flex: 1 1 0px;
    max-width: ${FILTER_MAX_WIDTH};
  }
`;

export const StyledFiltersAccordion: typeof FiltersAccordion = styled(FiltersAccordion)`
  margin-bottom: ${spacing.xs};
`;

export const multiselectStyles: Pick<MultiselectProps<string>, 'variant' | 'borderStyle'> = {
  variant: 'condensed',
  borderStyle: 'full',
};

export const inputFieldStyles: Pick<InputFieldProps, 'size'> = {
  size: 's',
};

export const LimitedMultiselect = styled(Multiselect)<MultiselectProps<string> & { $limitReached: boolean }>`
  ${({ $limitReached }) =>
    $limitReached &&
    css`
      li[aria-selected='false'] > label > span {
        color: ${colors.fogGrey} !important;
      }

      li[aria-selected='false']:hover,
      li[aria-selected='false']:hover > label {
        background-color: ${colors.snowWhite} !important;
        cursor: not-allowed !important;
      }
    `}
`;

export const ActionComponentsContainer = styled(HorizontalContainer)`
  flex: 1;
  gap: ${spacing.xxs};
`;
