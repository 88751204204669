import { SelectChangeEvent, Stylable } from '@components/types';
import {
  SelectProps as ElementalSelectProps,
  MultiselectProps as ElementalMultiselectProps,
  Select as ElementalSelect,
  Multiselect as ElementalMultiselect,
} from '@fortum/elemental-ui';
import { ChangeEvent, FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SelectContainer } from './styles';

/**
 * The aim of this component is to combine functionalities of Elemental Select and Multiselect components
 * The desired functionality that comes from the Multiselect is the possibility to enter the text after the component is clicked,
 * that is used to filter the items list.
 * The Select component was combined here to provide the desired look of the component when it is closed -
 * - displayed value with the chevron down icon and interactive border
 */

type PickedElementalSelectProps = Pick<
  ElementalSelectProps<string>,
  | 'borderStyle'
  | 'disabled'
  | 'displayValue'
  | 'error'
  | 'errorMessage'
  | 'id'
  | 'items'
  | 'maxWidth'
  | 'name'
  | 'placeholder'
  | 'selected'
  | 'size'
  | 'width'
  | 'variant'
>;

type PickedElementalMultiselectProps = Pick<ElementalMultiselectProps<string>, 'filterItems'>;

export type BasicSelectProps = Stylable &
  PickedElementalSelectProps &
  PickedElementalMultiselectProps & {
    onSelectedItemChange: (selectedItem: string) => void;
    label: string;
  };

export const BasicSelect: FC<BasicSelectProps> = ({
  name,
  disabled,
  selected,
  onSelectedItemChange,
  filterItems,
  displayValue,
  className,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isInputUsed, setIsInputUsed] = useState(false);

  const { selectName, multiselectName, multiselectSelector } = useMemo(() => {
    const multiselectName = `${name}-multiselect`;

    return {
      selectName: `${name}-select`,
      multiselectName,
      multiselectSelector: `#${multiselectName}`,
    };
  }, [name]);

  useLayoutEffect(() => {
    if (!isInputUsed || disabled) return;

    const currentRef = containerRef.current;
    const multiselectElement = currentRef?.querySelector<HTMLElement>(multiselectSelector);

    const listener = () => {
      setIsInputUsed(false);
    };

    multiselectElement?.focus();
    multiselectElement?.addEventListener('blur', listener, { once: true });

    return () => currentRef?.querySelector<HTMLElement>(multiselectSelector)?.removeEventListener('blur', listener);
  }, [isInputUsed, multiselectSelector, disabled]);

  const onChange = useCallback(
    (e: ChangeEvent<SelectChangeEvent<string[]>>) => {
      const currentValues = e.target.value.filter(value => selected !== value);

      onSelectedItemChange(currentValues[0] || '');
      setIsInputUsed(false);
    },
    [onSelectedItemChange, selected],
  );

  const onSelectClick = useCallback(() => !disabled && setIsInputUsed(true), [disabled]);

  return (
    <SelectContainer ref={containerRef} className={className} $width={rest.width}>
      {isInputUsed ? (
        <ElementalMultiselect
          name={multiselectName}
          selected={selected ? [selected] : []}
          onChange={onChange}
          filterItems={filterItems}
          {...rest}
        />
      ) : (
        <ElementalSelect name={selectName} displayValue={displayValue} selected={selected} onClick={onSelectClick} {...rest} />
      )}
    </SelectContainer>
  );
};
