import styled from 'styled-components';
import { spacing, IconHelpUi } from '@fortum/elemental-ui';
import { VerticalContainer } from '@components/styles';

export const StyledHelpIcon = styled(IconHelpUi)`
  &:hover {
    cursor: pointer;
  }
`;

export const PopoverContentContainer = styled(VerticalContainer)`
  padding: ${spacing.xxs};
  max-width: 35rem;
  gap: ${spacing.xxxs};
`;
