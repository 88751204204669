import { HorizontalContainer } from '@components/styles';
import { spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';
import type { BasicSelectProps } from './BasicSelect';

export const SelectContainer = styled.div<{ $width: BasicSelectProps['width'] }>`
  width: ${({ $width }) => $width};
`;

export const SelectWithTooltipContainer = styled(HorizontalContainer)<{ $width: BasicSelectProps['width'] }>`
  gap: ${spacing.xxxxs};
  align-items: start;
  width: ${({ $width }) => $width};
`;
