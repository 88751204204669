import { FC, useCallback, useContext, useMemo } from 'react';
import { FormContainer, RequiredFormFieldsContainer, formDescriptionStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { mapOrderTypesToRadioItems } from '@utils/ordersCreation';
import { HorizontalRadioButtonGroup } from '@components/HorizontalRadioButtonGroup';
import { ContentText, RadioProps } from '@fortum/elemental-ui';
import { InputField, InputFieldProps } from '@components/InputField';
import { Textarea, TextareaProps } from '@components/Textarea/index';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';

const COMMENTS_DEFAULT_LENGTH_IN_CHARS = 120;

export const OrderCreationForm: FC = () => {
  const { t, i18n } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'orderDetails', 'common', 'errors']);

  const {
    availableOrderTypes,
    form,
    missingRequiredFields,
    isFieldRequired,
    setComments,
    setEquipmentQuantity,
    setEstimatedWasteQuantity,
    setSelectedOrderType,
    orderTileRef,
  } = useContext(SingleOrderCreationContext);

  const { equipmentQuantity, estimatedWasteQuantity, orderType, comments } = form.values;

  const orderTypeRadioGroupItems = useMemo<RadioProps[]>(
    () => mapOrderTypesToRadioItems(availableOrderTypes, form.formIdentifier, orderTileRef),
    [availableOrderTypes, form.formIdentifier],
  );

  const getInputUniqueId = useCallback((name: string) => `${name}-${form.formIdentifier}`, [form.formIdentifier]);

  const containerElement = orderTileRef.current ?? undefined;

  const equipmentQuantityTooltipProps = useMemo<InputFieldProps['tooltipProps']>(
    () => ({
      content: [t(`orderCreation:form.equipmentQuantity.${orderType}.tooltip`)],
      containerElement,
    }),
    [i18n.language, containerElement, orderType],
  );

  const wasteQuantityTooltipProps = useMemo<InputFieldProps['tooltipProps']>(
    () => ({
      content: [t('orderCreation:form.estimatedWasteQuantity.tooltip')],
      containerElement,
    }),
    [i18n.language, containerElement],
  );

  const commentsTooltipProps = useMemo<TextareaProps['tooltipProps']>(
    () => ({
      content: [t('orderCreation:form.comments.tooltip.0'), t('orderCreation:form.comments.tooltip.1')],
      containerElement,
    }),
    [i18n.language, containerElement],
  );

  return (
    <FormContainer data-testid="form-container">
      <ContentText {...formDescriptionStyles}>{t('orderCreation:formDescription')}</ContentText>

      <HorizontalRadioButtonGroup
        data-testid="order-type-radio-button-group"
        label={`${t('orderDetails:orderTypeFieldLabel')}:`}
        items={orderTypeRadioGroupItems}
        value={orderType}
        onChange={setSelectedOrderType}
      />

      <RequiredFormFieldsContainer>
        <InputField
          data-testid="estimated-quantity-input-field"
          label={t(`orderCreation:form.equipmentQuantity.${orderType}.label`)}
          name={getInputUniqueId('eq-quantity')}
          value={equipmentQuantity || ''}
          onChange={setEquipmentQuantity}
          placeholder={t('common:typeHere')}
          required={isFieldRequired('equipmentQuantity')}
          errorMessage={t('errors:form.fieldRequired')}
          showError={missingRequiredFields.includes('equipmentQuantity')}
          tooltipProps={equipmentQuantityTooltipProps}
        />

        {(orderType === 'COLLECTION' || orderType === 'EMPTYING') && (
          <InputField
            data-testid="estimated-waste-quantity-input-field"
            label={t('orderCreation:form.estimatedWasteQuantity.label')}
            name={getInputUniqueId('estimated-waste-quantity')}
            value={estimatedWasteQuantity || ''}
            onChange={setEstimatedWasteQuantity}
            placeholder={t('common:typeHere')}
            required={isFieldRequired('estimatedWasteQuantity')}
            errorMessage={t('errors:form.fieldRequired')}
            showError={missingRequiredFields.includes('estimatedWasteQuantity')}
            tooltipProps={wasteQuantityTooltipProps}
          />
        )}
      </RequiredFormFieldsContainer>

      <Textarea
        data-testid="order-creation-textarea-field"
        name={getInputUniqueId('comments')}
        placeholder={t('common:typeHere')}
        onChange={setComments}
        value={comments}
        resizable={false}
        label={t('orderCreation:form.comments.label')}
        required={isFieldRequired('comments')}
        errorMessage={t('errors:form.fieldRequired')}
        showError={missingRequiredFields.includes('comments')}
        maxLength={COMMENTS_DEFAULT_LENGTH_IN_CHARS}
        tooltipProps={commentsTooltipProps}
      />
    </FormContainer>
  );
};
