import React, { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { TextareaProps as ElementalTextAreaProps } from '@fortum/elemental-ui';
import { Testable } from '@components/types';
import {
  Container,
  GeneralContainer,
  ReadOnlyContainer,
  ReadOnlyTextarea,
  StyledCounter,
  StyledErrorMessage,
  StyledTextarea,
  TextareaLabel,
} from './styles';

type NativeTextareaProps = ComponentPropsWithoutRef<'textarea'>;

export type BasicTextareaProps = {
  label: string;
  name: string;
  required?: boolean;
  resizable?: boolean;
  errorMessage?: string;
  showError?: boolean;
  size?: ElementalTextAreaProps['size'];
} & Testable &
  Omit<NativeTextareaProps, 'size'>;

const COMMENTS_INITIAL_LENGTH_IN_CHARS = 0;

export const BasicTextarea: FC<BasicTextareaProps> = ({
  label,
  name,
  errorMessage,
  resizable = true,
  showError = false,
  size = 'l',
  'data-testid': testId,
  readOnly = false,
  disabled,
  ...nativeProps
}) => {
  const activeTextLength = nativeProps.value?.toString().length || COMMENTS_INITIAL_LENGTH_IN_CHARS;
  const limitReached = activeTextLength === nativeProps.maxLength;

  const labelText = useMemo<string>(() => (nativeProps.required ? `*${label}` : label), [nativeProps.required, label]);

  const { labelId, errorMessageId } = useMemo(() => ({ labelId: `${name}_label`, errorMessageId: `${name}_error` }), [name]);

  return readOnly ? (
    <ReadOnlyContainer>
      <ReadOnlyTextarea
        id={name}
        $size={size}
        readOnly={readOnly}
        aria-labelledby={labelId}
        aria-required={nativeProps.required || undefined}
        {...nativeProps}
      />
      <TextareaLabel id={labelId} htmlFor={name}>
        {labelText}
      </TextareaLabel>
    </ReadOnlyContainer>
  ) : (
    <GeneralContainer>
      <Container data-testid={testId} $error={showError} $disabled={!!disabled}>
        <StyledTextarea
          id={name}
          $size={size}
          $error={showError}
          $resizable={resizable}
          disabled={disabled}
          aria-labelledby={labelId}
          aria-required={nativeProps.required || undefined}
          aria-invalid={!!showError || undefined}
          aria-describedby={showError ? errorMessageId : undefined}
          aria-disabled={!!disabled || undefined}
          {...nativeProps}
        />

        <TextareaLabel id={labelId} htmlFor={name}>
          {labelText}
        </TextareaLabel>
      </Container>
      {showError && (
        <StyledErrorMessage id={errorMessageId} active>
          {errorMessage}
        </StyledErrorMessage>
      )}
      <StyledCounter $limitReached={limitReached}>
        {activeTextLength}/{nativeProps.maxLength}
      </StyledCounter>
    </GeneralContainer>
  );
};
