import { Contact } from '@models/contact';
import { FC, RefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { getContactDescription } from '@utils/contact';
import { SelectItem } from '@fortum/elemental-ui';
import { Select, SelectProps } from '@components/Select';
import { filterSelectItems } from '@utils/dataOperations';
import { UseQueryResult } from '@tanstack/react-query';

interface ContactsProps {
  contactsDataHandler: UseQueryResult<Contact[], Error>;
  setSelectedContactPerson: (person: Contact) => void;
  selectedContactPersonId: string | undefined;
  orderTileRef?: RefObject<HTMLDivElement>;
}

export const Contacts: FC<ContactsProps> = ({
  contactsDataHandler,
  setSelectedContactPerson,
  selectedContactPersonId,
  orderTileRef,
}) => {
  const { t, i18n } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'errors']);
  const { data: contacts, isLoading, isError } = contactsDataHandler;

  const disabled = useMemo(
    () => !contacts || isLoading || isError || (contacts.length === 1 && contacts[0].code === selectedContactPersonId),
    [contacts, selectedContactPersonId, isLoading, isError],
  );

  const displayValue = useMemo(() => {
    const contactPerson = contacts?.find(contact => contact.code === selectedContactPersonId);

    return contactPerson ? getContactDescription(contactPerson) : t('orderCreation:selectContactPerson');
  }, [selectedContactPersonId, contacts, i18n.language]);

  const items = useMemo<SelectItem<string>[]>(
    () => (contacts ? contacts.map(contact => ({ value: contact.code, name: contact.name })) : []),
    [contacts],
  );

  const handleSelectedItemChange = useCallback(
    (selectedContactPersonId: string) => {
      const contactPerson = contacts?.find(contact => contact.code === selectedContactPersonId);

      if (contactPerson) setSelectedContactPerson(contactPerson);
    },
    [contacts, setSelectedContactPerson],
  );

  const tooltipProps = useMemo<SelectProps['tooltipProps']>(
    () => ({
      content: [t('orderCreation:form.transportDetails.contactDetails.tooltip')],
      containerElement: orderTileRef?.current ?? undefined,
    }),
    [i18n.language],
  );

  return (
    <Select
      label={t('wasteDetails:transportDetails.contactDetails')}
      name="contacts"
      size="m"
      items={items}
      selected={selectedContactPersonId}
      onSelectedItemChange={handleSelectedItemChange}
      displayValue={displayValue}
      disabled={disabled}
      filterItems={filterSelectItems}
      error={isError}
      errorMessage={t('errors:generalError.failedToFetch')}
      tooltipProps={tooltipProps}
    />
  );
};
