import {
  ContentText,
  ContentTextProps,
  RadioButtonGroupProps as ElementalRadioButtonGroupProps,
  Radio,
  fontSizes,
  fontWeights,
} from '@fortum/elemental-ui';
import { FC, useCallback } from 'react';
import { RadioButtonsContainer, RadioButtonWithTooltipContainer } from './styles';
import { Testable } from '@components/types';
import { Tooltip, TooltipProps } from '@components/Tooltip';

export type RadioButtonItemProps = ElementalRadioButtonGroupProps['items'][number] & { tooltipProps?: Omit<TooltipProps, 'id'> };

type RadioButtonProps = Pick<HorizontalRadioButtonGroupProps, 'value' | 'onChange'> & {
  item: RadioButtonItemProps;
};

const labelStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  fontWeight: fontWeights.medium,
};

//TODO: use only checked prop when it is fixed
const RadioButton: FC<RadioButtonProps> = ({ item, value, onChange }) => {
  const checked = value === item.value;
  const dataTestId = `${item.value}${checked ? '-checked' : ''}`;

  return item.tooltipProps ? (
    <RadioButtonWithTooltipContainer>
      <Radio data-testid={dataTestId} value={item.value} label={item.label} id={item.id} onChange={onChange} checked={checked} />

      <Tooltip {...item.tooltipProps} id={`${item.id}-tooltip`} />
    </RadioButtonWithTooltipContainer>
  ) : (
    <Radio data-testid={dataTestId} value={item.value} label={item.label} id={item.id} onChange={onChange} checked={checked} />
  );
};

type HorizontalRadioButtonGroupProps = Pick<ElementalRadioButtonGroupProps, 'label' | 'onChange' | 'value'> &
  Testable & { items: RadioButtonItemProps[] };

export const HorizontalRadioButtonGroup: FC<HorizontalRadioButtonGroupProps> = ({
  label,
  onChange,
  value,
  items,
  'data-testid': testId,
}) => {
  const renderItems = useCallback(
    () => items.map(item => <RadioButton key={item.id} item={item} onChange={onChange} value={value} />),
    [items, value],
  );

  return (
    <RadioButtonsContainer data-testid={testId}>
      <ContentText {...labelStyles}>{label}</ContentText>

      {renderItems()}
    </RadioButtonsContainer>
  );
};
