import { FC } from 'react';
import { TextareaWithTooltipContainer } from './styles';
import { BasicTextarea, BasicTextareaProps } from './BasicTextarea';
import { Tooltip, TooltipProps } from '@components/Tooltip';

export interface TextareaProps extends BasicTextareaProps {
  tooltipProps?: Omit<TooltipProps, 'id'>;
}

export const Textarea: FC<TextareaProps> = ({ tooltipProps, name, ...textareaProps }) =>
  tooltipProps ? (
    <TextareaWithTooltipContainer>
      <BasicTextarea name={name} {...textareaProps} />
      <Tooltip {...tooltipProps} id={`${name}-tooltip`} />
    </TextareaWithTooltipContainer>
  ) : (
    <BasicTextarea name={name} {...textareaProps} />
  );
