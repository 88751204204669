import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { closeIconButtonStyles, HeaderContainer, headerTextStyles } from './styles';
import { ContentText, IconButton, IconCross } from '@fortum/elemental-ui';

interface CreationModalHeaderProps {
  onClose: () => void;
}

export const CreationModalHeader: FC<CreationModalHeaderProps> = ({ onClose }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <HeaderContainer data-testid="user-creation-header">
      <ContentText {...headerTextStyles}>{t('userCreation.header')}</ContentText>

      <IconButton
        aria-label={t('common:close')}
        data-testid="user-creation-modal-close-button"
        status="plain"
        onClick={onClose}
        icon={IconCross}
        {...closeIconButtonStyles}
      />
    </HeaderContainer>
  );
};
