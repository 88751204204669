import { FC, useContext } from 'react';
import { gridStyles, columnStyles, VerticalContainerWithBigGap } from './styles';
import { Grid, Row, Col } from '@fortum/elemental-ui';
import { EquipmentDetails, TransportDetailsForm, InvoicingDetailsForm } from '@routes/ordersCreation/components';
import { Testable } from '@components/types';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';

export const DetailsForm: FC<Testable> = ({ 'data-testid': dataTestId }) => {
  const {
    form,
    contract,
    contactsDataHandler,
    setContactPerson,
    setLocationPointAdditionalInfo,
    setOrderLevelReference,
    orderTileRef,
  } = useContext(SingleOrderCreationContext);

  const { invoicingDetails } = contract.contractDetails;

  return (
    <Grid {...gridStyles} data-testid={dataTestId}>
      <Row>
        <Col {...columnStyles}>
          <TransportDetailsForm
            form={form}
            contract={contract}
            contactsDataHandler={contactsDataHandler}
            setContactPerson={setContactPerson}
            setLocationPointAdditionalInfo={setLocationPointAdditionalInfo}
            orderTileRef={orderTileRef}
          />
        </Col>

        <Col {...columnStyles}>
          <VerticalContainerWithBigGap>
            <EquipmentDetails equipmentDetails={contract.serviceDetails.equipmentDetails} />
            <InvoicingDetailsForm
              form={form}
              invoicingDetails={invoicingDetails}
              setOrderLevelReference={setOrderLevelReference}
              orderTileRef={orderTileRef}
            />
          </VerticalContainerWithBigGap>
        </Col>
      </Row>
    </Grid>
  );
};
